
export const getDirName = (dir) => {
  if (dir[dir.length - 1] === '/') {
    dir = dir.substring(0, dir.length - 1)
  }
  const dirs = dir.split('/')
  return dirs.slice(0, dirs.length - 1).join('/')
}
export const getFileExt = (name) => {
  const s = name.split('.')
  if (s.length > 1) {
    return s[s.length - 1]
  } else {
    return ''
  }
}

export const isVideo = (ext) => {
  const reg = /(mp4)$/i
  return reg.test(ext)
}
export const isAudio = (ext) => {
  const reg = /(mp3)$/i
  return reg.test(ext)
}
