<template>
  <div class="usbPage" @keydown="returnFn($event)">
    <div
      ref="back"
      class="back"
      tabindex="1"
      @keydown="keyDown($event)"
      @click="goPath('/')"
    >
      Exit
    </div>
    <div id="title">usb</div>
    <div class="list">
      <div
        v-if="currentDir !== rootDir"
        tabindex="1"
        class="item"
        @keydown="listKeyDown($event)"
        @click="
          open({
            title: '..',
            isDirectory: true,
          })
        "
      >
        <img :src="require('@/assets/img/files.png')" />
        <span>..</span>
      </div>
      <div
        v-for="item in dirList"
        tabindex="1"
        :key="item.title"
        class="item"
        @keydown="listKeyDown($event)"
        @click="open(item)"
      >
        <img
          :src="
            item.isDirectory
              ? require('@/assets/img/files.png')
              : require('@/assets/img/file.png')
          "
        />
        <span>{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { keyMap } from '@/util/keyUtil.js'
import { getDirName, getFileExt, isVideo, isAudio } from '@/util/usbUtil.js'
export default {
  props: {
    dir: {
      type: String,
      default: '/mnt'
    }
  },
  data () {
    return {
      rootDir: '/mnt',
      currentDir: '/mnt',
      dirList: []
    }
  },
  created () {
    this.currentDir = this.dir
    console.log(this.currentDir)
    this.$plugin.send({
      data: [0x55, 0xaa, 0x02, 0x01, 0x00, 0x16]
    })
    this.dirList = this.$plugin.getFiles(this.currentDir).filter(item => (isVideo(getFileExt(item.title)) || isAudio(getFileExt(item.title))))
  },
  mounted () {
    document.querySelectorAll('.list .item')[0].focus()
  },
  methods: {
    keyDown (e) {
      const key = keyMap[e.keyCode]
      const target = e.target
      if (key === 'down') {
        document.querySelectorAll('.list .item')[0].focus()
      } else if (key === 'ok') {
        target.click()
      }
    },
    open (item) {
      if (item.isDirectory) {
        if (item.title !== '..') {
          this.currentDir = this.currentDir + '/' + item.title
        } else {
          this.currentDir = getDirName(this.currentDir)
        }
        this.dirList = this.$plugin.getFiles(this.currentDir)
      } else if (
        isVideo(getFileExt(item.title)) ||
        isAudio(getFileExt(item.title))
      ) {
        const dir = this.currentDir + '/' + item.title
        console.log('play ' + dir)
        const datas = {
          type: isVideo(getFileExt(item.title)) ? 'video' : 'audio',
          url: isVideo(getFileExt(item.title))
            ? require('@/assets/video/yanzheng.mp4')
            : require('@/assets/video/123.mp3'),
          title: item.title,
          dir: this.currentDir
        }
        if (window.iBrowser) {
          datas.url = dir
        }
        this.$router.push({
          name: 'play',
          params: {
            datas,
            mediaList: this.mediaList
          }
        })
      }
    },
    goPath (path) {
      this.$router.push(path)
    },
    listKeyDown (e) {
      const key = keyMap[e.keyCode]
      const target = e.target
      if (key === 'down') {
        if (target.nextSibling) {
          target.nextSibling.focus()
        }
      } else if (key === 'up') {
        if (target.previousSibling) {
          target.previousSibling.focus()
        }
        // else {
        //   this.$refs.back.focus()
        // }
      } else if (key === 'ok') {
        target.click()
      }
    },
    returnFn (e) {
      const key = keyMap[e.keyCode]
      if (key === 'return') {
        this.$router.replace('/')
      }
    }
  },
  computed: {
    mediaList: function () {
      return {
        currentDirVideo: this.dirList.reduce((preItem, nextItem) => {
          if (isVideo(getFileExt(nextItem.title))) {
            preItem.push({
              type: isVideo(getFileExt(nextItem.title)) ? 'video' : 'audio',
              url: this.currentDir + '/' + nextItem.title,
              title: nextItem.title,
              dir: this.currentDir
            })
          }
          return preItem
        }, []),
        currentDirAudio: this.dirList.reduce((preItem, nextItem) => {
          if (isAudio(getFileExt(nextItem.title))) {
            preItem.push({
              type: isAudio(getFileExt(nextItem.title)) ? 'video' : 'audio',
              url: this.currentDir + '/' + nextItem.title,
              title: nextItem.title,
              dir: this.currentDir
            })
          }
          return preItem
        }, [])
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.usbPage {
  .back {
    display: block;
    width: 80px;
    font-size: 30px;
    text-align: center;
    color: #fff;
    border-radius: 20px;
    &:focus {
      background: gray;
    }
  }
  #title {
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
  }
  .list {
    box-sizing: border-box;
    padding: 30px;
    width: 100%;
    height: 700px;
    overflow-y: auto;
    .item {
      border-radius: 10px;
      padding: 5px;
      width: 100%;
      color: #fff;
      font-size: 40px;
      margin-bottom: 5px;
      &:focus {
        background: gray;
      }
    }
  }
}
</style>
